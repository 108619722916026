import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueCookies from 'vue-cookies';

Vue.use(VueRouter);
Vue.use(VueCookies, { expire: '14d'});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gift-cards',
    name: 'Gift Cards',
    component: () => import(/* webpackChunkName: "login" */ '../views/GiftCards.vue')
  },
  {
    path: '/refund',
    name: 'Refund',
    component: () => import(/* webpackChunkName: "login" */ '../views/Refund.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
