<template>
  <v-app>
    <!-- <v-app-bar
      app
      dark
    >
      <div class="d-flex align-center">

      </div>

      <v-spacer></v-spacer>

    </v-app-bar> -->

    <v-navigation-drawer
      app
      permanent
      dark
    >
      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    items: [
      { title: 'Gift Cards', icon: 'mdi-card', route: '/gift-cards' },
      { title: 'Refund', icon: 'mdi-cash-multiple', route: '/refund' }
    ]
  }),
};
</script>
